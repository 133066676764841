<template>
  <b-overlay rounded="sm" :show="loading">
    <AuthWrapper>
      <template #card>
        <div class="text-center">
          <div>
            <h1>Reset Password</h1>
          </div>
          <div>
            <p>Your new password must be different from previously
              used passwords</p>
          </div>
        </div>
        <b-alert
            variant="danger"
            :show="errorMessage.length"
        >
          <div class="alert-body font-small-2">
            <b-card-text>
              <feather-icon icon="AlertCircleIcon" />
              <small>{{ errorMessage }}</small>
            </b-card-text>
          </div>
        </b-alert>
        <validation-observer ref="simpleRules" #default="{invalid}">
          <b-form @submit.prevent="validationForm">
            <!--                password -->
            <b-form-group label="New Password" label-for="reset-password-new">

              <validation-provider
                  #default="{ errors }"
                  name="Password"
                  :rules="{
                          required: true,
                          password: true
                      }"
              >
                <div class="exxtra-input">
                      <span class="leading">
                        <PasswordLock />
                      </span>
                  <input id="login-password" v-model="password"
                         :type="password1FieldType"
                         name="reset-password-new"
                         placeholder="*****"
                  >
                  <span class="trailing">
                    <feather-icon class="cursor-pointer" :icon="password1ToggleIcon" @click="togglePassword1Visibility" />
                  </span>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!--                  Confirm password -->
            <b-form-group label="Confirm Password" label-for="confirm-password">

              <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
              >
                <div class="exxtra-input">
                        <span class="leading">
                          <PasswordLock />
                        </span>
                  <input
                      id="reset-password-confirm"
                      v-model="cPassword"
                      :type="password2FieldType"
                      placeholder="*****"
                  >
                  <span class="trailing">
                    <feather-icon class="cursor-pointer" :icon="password2ToggleIcon" @click="togglePassword2Visibility" />
                  </span>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div>
              <b-button
                  class="py-1"
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
              >
                Set New Password
              </b-button>
            </div>
            <p class="text-center mt-2">
              <b-link :to="{ name:'auth-login' }">
                <feather-icon icon="ChevronLeftIcon" />
                Back to login
              </b-link>
            </p>
          </b-form>
        </validation-observer>
      </template>
    </AuthWrapper>
  </b-overlay>
</template>

<script>
import BottomDesign from '@/assets/svg/BottomDesign.svg';
import PasswordLock from "@/assets/svg/PasswordLock.svg";
import Rectangles from "@/pages/auth/shared-components/rectangles.vue";
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppLogo from '@core/layouts/components/Logo.vue';

import { required } from '@validations'
import { getPasswordErrorMessage } from '@/@core/utils/utils';

import {
  BAlert,
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BOverlay,
} from 'bootstrap-vue'
import { get } from "lodash"
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    AuthWrapper,
    Rectangles,
    BottomDesign,
    PasswordLock,
    AppLogo,
    BOverlay,
    BAlert,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      cPassword: '',
      password: '',
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    errorMessage() {
      const settings = this.$store.getters['auth/settings']
      return ` ${getPasswordErrorMessage(settings.password_complexity_settings)}`
    }
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    async validationForm() {
      try {
        this.loading = true;

        const success = await this.$refs.simpleRules.validate();
        if (!success) {
          return;
        }

        await this.useJwt()
            .authService
            .resetPassword({
              password: this.password
            })

        this.useJwt().authService.clearPasswordResetToken()

        this.$router.replace({ name: "auth-login" })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Password changed successfully.',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              });
            })
            .catch(() => {
            });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
